#aboutUsSection p {
  font-size: 0.9rem;
  text-align: left;
}
.mobile-only {
  display: none;
}

.link {
  text-decoration: underline;
  color: #4b0082;
}

.illustration {
  display: flex;
  vertical-align: center;
}

#screenImage img {
  height: 120%;
}

@media only screen and (max-width: 768px) {
  #aboutUsSection p {
    font-size: 1.1rem;
  }
  #aboutUsSection h2 {
    text-align: left;
    align-items: 'left';
  }
  #aboutUsSection hr {
    margin-left: 0;
  }
  .desktop-only {
    display: none;
  }
  .mobile-only {
    display: block;
  }
  .illustration {
    display: none;
  }
}
