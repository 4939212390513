#home {
  height: 70vh;
  min-height: 260px;
  background-size: 100% auto;
}
#shape {
  height: 34vw;
  min-height: 70vh;
  margin-right: -15%;
}
#ventilator {
  margin-left: -15vw;
  width: 190%;
  margin-top: calc(-80vh + 10vw);
}

@media only screen and (max-width: 768px) {
  #home {
    height: 70vw;
  }
  #shape {
    height: 70vw;
    margin-right: 30%;
  }
  #ventilator {
    display: block;
  }
  #home {
    background-size: auto 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1000px) {
  #ventilator {
    margin-top: calc(-70vh + 10vw);
  }
  #home {
    background-size: auto 100%;
  }
}

@media only screen and (min-width: 1000px) and (max-width: 1220px) {
  #home {
    background-size: auto 100%;
  }
  #ventilator {
    margin-top: calc(-65vh + 4vw);
  }
}

@media only screen and (min-width: 1220px) and (max-width: 1310px) {
  #ventilator {
    margin-top: calc(-80vh + 10vw);
  }
}
