//Add custom values here

$yellow: #ffe200;
$blue: #001b66;
$body-bg: #e5e5e5;
$body-color: $blue;
$navbar-light-color: $blue;
$light: $yellow;
$dark: $blue;

//global headings
$headings-font-weight: bold;
$headings-font-family: 'Exo', sans-serif;

//jumbotron
$jumbotron-bg: rgba(0, 27, 102, 1);
$jumbotron-color: white;

//Navbar
$navbar-light-bg: $yellow;

//options
$enable-responsive-font-sizes: true;
html {
  font-size: calc(0.5em + 1vw);
}

@import '~bootstrap/scss/bootstrap.scss';

//fonts
@import url('https://fonts.googleapis.com/css2?family=Exo:wght@400;700&display=swap');
