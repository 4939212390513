#whyUsSection {
  padding: 5%;
}

.intro p {
  text-align: center;
  font-size: 0.9rem;
}

.text-content {
  font-size: 0.9rem;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
}

.card-title {
  height: 15%;
  position: relative;
}

.card-icon {
  float: left;
  height: 70%;
}

@media only screen and (max-width: 745px) {
  .text-content {
    font-size: 0.5rem;
  }
}

@media only screen and (max-width: 786px) {
  .intro p {
    text-align: left;
    font-size: 1.1rem;
  }
  #whyUsSection h2 {
    text-align: left;
  }
  #whyUsSection hr {
    margin-left: 0;
  }

  .card-text {
    height: 90%;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .card-text::-webkit-scrollbar {
    width: 2%;
  }

  .card-text::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.2);
  }

  .card-text::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.2);
  }
}

@media only screen and (max-width: 1225px) {
  .text-content {
    font-size: 0.8rem;
  }
}

@media only screen and (max-width: 965px) {
  .text-content {
    font-size: 0.6rem;
  }
}

@media only screen and (max-width: 576px) {
  .card-text {
    overflow: hidden;
    height: auto;
    margin-top: 10%;
  }

  .process-card .card {
    height: 40vh;
  }

  .text-content {
    font-size: 1rem;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .text-content p {
    margin-top: 0;
  }

  .card-title {
    height: 25%;
  }

  .process-cards .card {
    height: 38vh;
    overflow: hidden;
  }
}
