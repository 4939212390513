.ventilation-modes {
  padding: 12%;
  width: 90%;
  text-align: left;
  overflow-y: hidden;
}

.long-row {
  height: 90vh;
}

.short-row {
  height: 80vh;
}

.monitored-parameters {
  color: #001b66;
  width: 90%;
  padding: 12%;
  padding-bottom: 5%;
  text-align: left;
  overflow-y: hidden;
}

@media only screen and (max-width: 768px) {
  .long-row {
    height: auto;
  }

  .short-row {
    height: auto;
  }
}
