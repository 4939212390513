.product-intro {
  text-align: left;
  font-size: 0.9rem;
}

.key-features {
  margin-top: 15vh;
  text-align: left;
}

.key-features li {
  padding: 5px 0;
}

.key-features-content {
  z-index: 5;
  position: relative;
  margin-left: 6vw;
}

.key-features-image {
  margin-left: -22vw;
  margin-top: -55vw;
  width: 82vw;
  z-index: -1;
  position: relative;
}

.mobile-view {
  display: none;
}

.desktop-view {
  padding: 2%;
  padding-bottom: 0;
}

@media only screen and(min-width:1230px) and (max-width: 1300px) {
  .key-features {
    margin-top: 1vh;
  }
  .key-features-image {
    margin-top: -68vw;
    margin-left: -35vw;
  }
}

@media only screen and (min-width: 1150px) and (max-width: 1230px) {
  .key-features {
    margin-top: 10vh;
  }
  .key-features-image {
    margin-top: -58vw;
  }
}

@media only screen and (min-width: 1035px) and (max-width: 1150px) {
  .key-features {
    margin-top: 5vh;
  }
  .key-features-image {
    margin-top: -58vw;
    margin-left: -20vw;
    width: 84vw;
  }
}

@media only screen and (min-width: 925px) and (max-width: 1035px) {
  .key-features {
    margin-top: 8vh;
  }
  .key-features-image {
    margin-top: -60vw;
    margin-left: -25vw;
    width: 90vw;
  }
}

@media only screen and (min-width: 768px) and (max-width: 925px) {
  .key-features {
    margin-top: 10vh;
  }
  .key-features-image {
    margin-top: -68vw;
    margin-left: -25vw;
    width: 98vw;
  }
}

@media only screen and (max-width: 768px) {
  .desktop-view {
    display: none;
  }

  .mob-product-intro {
    padding: 2%;
  }

  .mobile-view {
    display: block;
    text-align: left;
  }

  .mobile-gallery {
    padding: 4%;
  }

  .mob-product-heading {
    padding: 2%;
  }

  .mob-key-features {
    background-color: #ffe200;
    height: 85vh;
    margin-top: -25vh;
    padding-top: 22vh;
  }
}
