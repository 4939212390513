#logo {
  width: 90%;
}

@media only screen and (max-width: 768px) {
  #logo {
    width: 70%;
  }
  .navbar-brand {
    padding: 0;
    width: 50%;
    margin: 0;
  }
}
