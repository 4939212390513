.mobile-only {
  display: none;
}

.footer {
  background-color: #001b66;
  color: white;
  font-size: 0.7rem;
  text-align: left;
  overflow-x: hidden;
}

.footer-logo {
  background-color: #ffe200;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: skew(-20deg);
  margin-left: -2%;
}

.legal {
  color: #6072af;
  margin-left: 5px;
}

.hover-trigger {
  text-decoration: underline;
}

.hover-trigger:hover {
  color: #ffe200;
}

.mobile-only:hover {
  color: #ffe200;
}

.footer-icon {
  padding-right: 5px;
}

.legal-content {
  font-size: 0.7rem;
}

.anti-skew {
  transform: skew(20deg);
}

@media only screen and (max-width: 768px) {
  .footer-logo {
    margin-left: 10vw;
    max-width: 30vw;
  }

  #address {
    padding: 0 5%;
  }

  .legal-content p {
    margin: 0;
    padding: 0;
  }

  .contact {
    padding: 6%;
  }

  .mobile-only {
    display: block;
  }

  .desktop-only {
    display: none;
  }
}
